@import '@wordpress/components/build-style/style.css';
@import '@wordpress/block-editor/build-style/style.css';
@import '@wordpress/block-library/build-style/style.css';
@import '@wordpress/block-library/build-style/editor.css';
@import '@wordpress/block-library/build-style/theme.css';
// @import '@wordpress/format-library/build-style/style.css';

@import '@mindspun/mrblx/dist/mrblx.css';
@import "@saa/library/src/scss/style";

$primary-color: #FF50A9;


.saa-theme-editor, .components-modal__frame {
    font-family: "Source Sans 3", sans-serif;
    font-optical-sizing: auto;
}

.saa-theme-editor {
    .saa-header {
        color: $primary-color;
        .components-button.is-primary {
            background-color: $primary-color;
        }
    }
}

.components-modal__frame {
    h2 {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 16px;
        letter-spacing: -0.72px;
    }

    p {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.2px;
    }

    .saa-learn-more {
        color: white;
        background-color: $primary-color;
        padding: 16px 24px;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;

    }

    .saa-learn-more:hover {
        background-color: black;
    }
}