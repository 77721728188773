@import "variables";

.saa-theme-editor {
    label {
        font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif !important;
    }

    .saa-header {
        height: $header-height;

        > * {
            margin: 0;
            padding: 0 0 0 16px;
        }
    }

    .saa-stripe-container {
        max-width: 600px;
        margin: 0 auto;
    }

    .saa-sidebar {
        color: $gray-900;
        background-color: #fff;
        width: 0;
        position: absolute;
        right: 0;
        top: $header-height;
        min-height: calc(100vh - $header-height - $admin-bar-height);

        .components-panel__row label {
            max-width: none;
        }
    }

    &.saa-sidebar-open {
        .saa-sidebar {
            width: $sidebar-width;
            z-index: 100;
        }

        @media (min-width: 960px) {
            .saa-main {
                margin-right: $sidebar-width;
            }
            .saa-codemirror {
                right: $sidebar-width;
            }
        }
    }

    .saa-codemirror {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
