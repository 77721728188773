$red: #DF1B41;
$blue: #005FFE;
$gray: #424856;
$green: #198754;
$light-gray: #a0aec0;

$gray-200: #e0e0e0 !default;
$gray-900: #1e1e1e !default;

$editor-border-gray: #ddd;

$admin-bar-height: 32px !default;
$header-height: 60px !default;
$sidebar-width: 280px;
