@import "variables";

.saa-mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.saa-float-right {
    float: right;
}

.saa-w-100 {
    width: 100%;
}

$spacings: 0, .25em, .5em, 1em, 1.5em, 3em;

@for $i from 1 through length($spacings) {
    $spacing: nth($spacings, $i);

    .saa-m-#{$i - 1} {
        margin: $spacing !important;
    }

    .saa-mx-#{$i - 1} {
        margin-left: $spacing !important;
        margin-right: $spacing !important;
    }

    .saa-my-#{$i - 1} {
        margin-top: $spacing !important;
        margin-bottom: $spacing !important;
    }

    .saa-mt-#{$i - 1} {
        margin-top: $spacing !important;
    }

    .saa-mb-#{$i - 1} {
        margin-bottom: $spacing !important;
    }

    .saa-me-#{$i - 1} {
        margin-right: $spacing !important;
    }

    .saa-ms-#{$i - 1} {
        margin-left: $spacing !important;
    }

    .saa-p-#{$i - 1} {
        padding: $spacing !important;
    }

    .saa-px-#{$i - 1} {
        padding-left: $spacing !important;
        padding-right: $spacing !important;
    }

    .saa-py-#{$i - 1} {
        padding-top: $spacing !important;
        padding-bottom: $spacing !important;
    }

    .saa-pt-#{$i - 1} {
        padding-top: $spacing !important;
    }

    .saa-pb-#{$i - 1} {
        padding-bottom: $spacing !important;
    }

    .saa-pe-#{$i - 1} {
        padding-right: $spacing !important;
    }

    .saa-ps-#{$i - 1} {
        padding-left: $spacing !important;
    }
}

.saa-d-block {
    display: block !important;
}

.saa-d-flex {
    display: flex !important;
}

.saa-flex-column {
    flex-direction: column;
}

.saa-flex-wrap {
    flex-wrap: wrap;
}

.saa-flex-nowrap {
    flex-wrap: nowrap;
}

.saa-justify-content-center {
    justify-content: center;
}

.saa-justify-content-left {
    justify-content: left;
}

.saa-justify-content-right {
    justify-content: right;
}

.saa-justify-content-between {
    justify-content: space-between;
}

.saa-align-items-center {
    align-items: center;
}

.saa-flex-grow-1 {
    flex-grow: 1;
}

.saa-d-none {
    display: none;
}

.saa-text-center {
    text-align: center !important;
}

.saa-position-relative {
    position: relative
}

.saa-text-danger {
    color: $red;
}

.saa-border-1 {
    border: 1px $editor-border-gray solid;
}